document.addEventListener('turbolinks:load', initContent, false);

function initContent() {
  generateCarousels();
  generateVideo();
  generateDownloadTable();
  generateDownloadButtons();
  presizeImages();
  generateScrollTo();
  generatePrevNext();
  navScroll();
  generateBrandHover();
  nearestSection();
  generateDownloadNotifications();
}
Siema.prototype.addArrows = function() {
  const carouselControlsRight = this.selector.parentNode.querySelector('.carousel-controls-right');
  
  this.prevArrow = document.createElement('button');
  this.nextArrow = document.createElement('button');
  
  this.prevArrow.textContent = '←';
  this.nextArrow.textContent = '→';
  
  carouselControlsRight.appendChild(this.prevArrow)
  carouselControlsRight.appendChild(this.nextArrow)
  
  this.prevArrow.addEventListener('click', () => this.prev());
  this.nextArrow.addEventListener('click', () => this.next());
}

Siema.prototype.addProgressText = function() {
  const carouselControlsLeft = this.selector.parentNode.querySelector('.carousel-controls-left');
  
  this.currentSlideEl = document.createElement('span');
  this.maxSlides = document.createElement('span');
  this.maxSlides.classList.add('max-slides');
  
  this.currentSlideEl.textContent = 1;
  this.maxSlides.textContent = this.innerElements.length;
  
  carouselControlsLeft.appendChild(this.currentSlideEl)
  carouselControlsLeft.appendChild(this.maxSlides)
}

Siema.prototype.addProgressBar = function() {
  this.progress = document.createElement('progress');
  this.progress.max = 5000;
  this.progress.value = 0;
  
  this.progressInterval = setInterval(() => {
    this.progress.value = new Date() - this.startTimerTime;
  }, 10);
  
  this.selector.appendChild(this.progress);
}


function generateCarousels() {
  const carousels = document.querySelectorAll('.module-carousel');

  for(const carousel of carousels) {
    const instance = new Siema({
      selector: carousel,
      duration: 250,
      loop: true,
      onChange: onChangeCallback,
      onBuildSliderFrame: onBuildSliderFrameCallback
    })
    
    function onChangeCallback() {
      instance.resetTimer();
      instance.currentSlideEl.textContent = instance.currentSlide + 1;
    }
    
    function onBuildSliderFrameCallback() {
      this.addProgressBar();
    }
    
    instance.resetTimer = () => {
      clearInterval(instance.timer);
      instance.startTimerTime = new Date();
      
      instance.timer = setInterval(() => {
        instance.startTimerTime = new Date();
        instance.next();
      }, 5000);
    }
    
    instance.startTimerTime = new Date();
    
    instance.addArrows();
    instance.resetTimer();
    instance.addProgressText();
    instance.addProgressBar();
  }  
}
function generateDownloadButtons() {
  const downloadDropdownBtns = document.querySelectorAll('.download-button');
  
  const toggleDownloadDropdown = debounce(function(index) {  
    const dropdown = document.querySelector('#download_dropdown_' + index);
    dropdown.classList.toggle('visible');
  }, 500, true);
  
  for (const btn of downloadDropdownBtns) {
    const index = btn.dataset.index;
    btn.addEventListener('click', () => { toggleDownloadDropdown(index) });
  }
}

function generateDownloadNotifications() {
  const downloadDisclaimerTriggers = document.querySelectorAll('.download-disclaimer-trigger');
  
  for (const downloadTrigger of downloadDisclaimerTriggers) {
    downloadTrigger.addEventListener('click', toggleDownloadDisclaimer);
  }
}

function toggleDownloadDisclaimer() {
  const downloadDisclaimer = document.querySelector('.download-disclaimer');
  
  downloadDisclaimer.classList.add('visible');
  
  setTimeout(() => {
    downloadDisclaimer.classList.remove('visible');
  }, 5000);
}

function generateDownloadTable() {
  var hasScrolled, iTimer;
  var interval = 0;

  var downloadsHeader = document.getElementById('table_header');
  var downloadsTable = document.getElementById('downloads_table')
  var backIcon = document.getElementById('back_icon');

  checkScroll();
  window.addEventListener("scroll", checkScroll);

  function checkScroll() {
    hasScrolled = true;
  }

  (function repeat() {
    if(!downloadsTable) return;
    intervalScroll();
    iTimer = setTimeout(repeat, interval);
  })();

  function intervalScroll() {
    if (hasScrolled) {
      hasScrolled = false;

      var distance = Math.floor(downloadsTable.getBoundingClientRect().top <= 0);
      
      if(distance) {
        downloadsHeader.classList.add('fixed');
      } else {
        downloadsHeader.classList.remove('fixed');
      }
    }
  }

  // Sorting
  if(downloadsTable) {
    const d = Dewey({
      list: '#downloads_table',
      items: '.table-body',
      sortAttribute: 'title'
    });

    d.sortItems();

    var selected;
    var direction = 'DESC';
    
    const tableSortBtns = document.querySelectorAll('.table-sort');

    for (const tableSortBtn of tableSortBtns) {
      tableSortBtn.addEventListener("click", () => {
        const filter = tableSortBtn.dataset.sortBy;
        sortBy(filter, tableSortBtn);
      });
    }   
    
    function sortBy(value, el) {
      d.sortBy(value);

      if((selected == el) || (selected !== el && direction == 'DESC'))
        direction = d.invertSort();

      selected = el;
      
      [].forEach.call(document.querySelectorAll('.selected_ASC, .selected_DESC'), function(e){
        e.classList.remove('selected_ASC');
        e.classList.remove('selected_DESC');
      });

      el.classList.add('selected_' + direction);
    }
  };  
}

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};
function generateBrandHover() {
  const brandLinks = document.querySelectorAll('.brand-link');
  const body = document.body;

  for (const link of brandLinks) {
    const brand = link.dataset.brand;
    
    link.addEventListener('mouseenter', () => { brandHover(brand, link) });
  }

  function brandHover(brand, link) {
    body.classList.remove('dusit-central-park');
    body.classList.remove('central-park');
    body.classList.remove('dusit-parkside');
    body.classList.remove('dusit-residences');
    
    body.classList.add(brand);
    
    removeActive();
    link.classList.add('active');
  }

  function removeActive() {
    for (const link of brandLinks) {
      link.classList.remove('active');
    }
  }  
}
function presizeImages() {
  const presizeImages = document.querySelectorAll('.image-presize');
  
  window.addEventListener('resize', setAspectRatio);
  
  setAspectRatio();
  
  function setAspectRatio() {
    for (const image of presizeImages) {
      const aspectRatio = image.dataset.aspectRatio;

      if(aspectRatio) {
        const width = image.offsetWidth;
        image.style.height = Math.floor(width * aspectRatio) + 'px';
      };
      
      if (image.complete) {
        loaded();
      } else {
        image.addEventListener('load', loaded);
      }
      
      function loaded() {
        image.classList.add('loaded');
      }
    }  
  }
}
function navScroll() {
  const nav = document.querySelector('.chapter-header');
  const hamburger = document.querySelector('#hamburger');
  const dropdownMenu = document.querySelector('#nav_menu');
  const sideMenu = document.querySelector('#menu');

  window.addEventListener("scroll", checkScroll);

  if(hamburger) {
    hamburger.addEventListener("click", toggleNav);    
  }
  var hasScrolled, iTimer;
  var interval = 250;
  
  checkScroll();

  function checkScroll() {
    hasScrolled = true;
  }

  (function repeat() {
    if(!nav) return;
    intervalScroll();
    iTimer = setTimeout(repeat, interval);
  })();

  function intervalScroll() {
    if (hasScrolled) {
      hasScrolled = false;
      
      const distance = window.scrollY;
      if(distance > 0) {
        nav.classList.add('not-top');
      } else {
        nav.classList.remove('not-top');
      }
    }
  }
  
  function toggleNav() {
    document.body.classList.toggle('nav-open');
    hamburger.classList.toggle('is-active');
    dropdownMenu.classList.toggle('is-active');
    sideMenu.classList.toggle('is-active');
  }
}
function nearestSection() {
  var navScroll;
  var waypoints  = document.getElementsByClassName('scroll-anchor');

  window.addEventListener("scroll", updateNav);

  function updateNav() {
    navScroll = true;
  }

  setInterval(function() {
    if(navScroll) {
      navScroll = false;

      var closestSection,
          closestDist = 400;

      Array.prototype.forEach.call(waypoints, function(e) {
        var elemTop = Math.abs(e.getBoundingClientRect().top);

        if(elemTop < 400 && elemTop < closestDist ) {
          closestDist = elemTop;
          closestSection = e;
        }

        // Check if near the bottom
        // Since it wont trigger if elements are too close/short
        var scrollPosition = window.pageYOffset;
        var windowSize = window.innerHeight;
        var bodyHeight = document.body.offsetHeight;

        if(Math.max(bodyHeight - (scrollPosition + windowSize), 0) < 400) {
          closestSection = waypoints[waypoints.length-1];
        }
      });

      if(closestSection) selectLink(closestSection);
    }
  }, 100);

  function selectLink(section) {
    // console.log(section);
    var toggle = document.getElementById(section.id + '_link');
    var selected  = document.getElementsByClassName('selected');
    
    for (i = 0; i < selected.length; ++i) {
      selected[i].classList.remove('selected');
    }
    
    toggle.classList.add('selected');
  }
}
function generatePrevNext() {
  const prevNext = document.querySelector('.chapter-prev-next');
  const main = document.querySelector('main');

  var hasScrolled, iTimer;
  var interval = 250;
  
  checkScroll();
  window.addEventListener("scroll", checkScroll);

  function checkScroll() {
    hasScrolled = true;
  }

  (function repeat() {
    if(!prevNext) return;
    intervalScroll();
    iTimer = setTimeout(repeat, interval);
  })();

  function intervalScroll() {
    if (hasScrolled) {
      hasScrolled = false;
      
      const distance = window.innerHeight - main.getBoundingClientRect().bottom;
            
      if(distance > -500) {
        prevNext.classList.add('visible');
      } else {
        prevNext.classList.remove('visible');
      }
    }
  }
}
function generateScrollTo() {
	const easeFunctions = {
    easeInQuad: function(t, b, c, d) {
      t /= d;
      return c * t * t + b;
    },
    easeOutQuad: function(t, b, c, d) {
      t /= d;
      return -c * t * (t - 1) + b;
    }
  }

  const moveTo = new MoveTo({
    easing: 'easeInQuad'
  }, easeFunctions);

  let trigger = Array.from(document.querySelectorAll('.js-trigger'));

  trigger.forEach(function(triggerItem) {
    moveTo.registerTrigger(triggerItem);
  });
}
function generateVideo() {
  const videos = document.querySelectorAll('video');

  for(const video of videos) {
    const autoplay = video.parentNode.classList.contains('video-autoplay');
    
    if(autoplay) { 
      video.muted = true;
      video.autoplay = true;
    }
    
    video.parentNode.classList.add('--paused');
    
    video.addEventListener('play', () => {
      video.parentNode.classList.remove('--paused');
    });

    video.addEventListener('pause', () => {
      video.parentNode.classList.add('--paused');
    });
    
    video.addEventListener('click', () => {
      if(video.paused) {
        video.play();
      } else {
        video.pause();
      }
    });
    
    const progressEl = video.parentNode.querySelector('progress');
    
    video.addEventListener('timeupdate', () => {
      const value = (100 / video.duration) * video.currentTime;
      progressEl.value = value;
    });
  }  
}